<template>
  <section class="brandManage">
    <h2 class="title_page">被动任务管理</h2>
    <div class="main">
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" ref="mutipleTable"
                :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
        <el-table-column prop="name" label="任务类型" align="center">
        </el-table-column>
        <el-table-column prop="row.store_task_pasv.name" label="显示名称" align="center">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.store_task_pasv.name" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="gw" label="推送岗位（可多选）" align="center" width="240">
          <template slot-scope="scope">
            <el-select multiple
                       style="width:200px;"
                       v-model="scope.row.store_task_pasv.station_id"
                       @change='changeSelect(scope.row)'
                       @remove-tag='removeTag(scope.row)'
                       placeholder='请选择'>
              <el-option label='全选' value='全选'
                         @click.native='selectAll(scope.row)'></el-option>
              <el-option v-for="(item, index) in stationList" :key="index" :value="item.id"
                         :label="item.name"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="任务分配模式" align="center" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.store_task_pasv.allot_mode"
                       placeholder='请选择'>
              <el-option v-for="(item, index) in allot_mode" :key="index" :value="item.id"
                         :label="item.name"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="row.store_task_pasv.accept_duration" label="单次接单时效（分钟）" align="center" width="200">
          <template slot-scope="scope">
             <el-input oninput="if(value.length==1){value=value.replace(/[^1-9]/g,'')}else{value=this.value.replace(/\D/g,'')}"     size="mini" min="1" v-model="scope.row.store_task_pasv.accept_duration" placeholder="请输入"></el-input>
            <!-- <el-input-number v-model="scope.row.store_task_pasv.accept_duration" :min="30" :step="30"></el-input-number> -->
          </template>
        </el-table-column>
         <el-table-column prop="row.store_task_pasv.accept_duration" label="抢单推送次数" align="center" width="130">
           <template slot="header">
             <span>抢单推送次数</span>
              <el-tooltip placement="top" popper-class="toolTipClass">
                <div slot="content">先派单再抢单模式下，派单推送次数默认为1次</div>
                <span class="explain"><i class="el-icon-warning-outline" style="margin-left: 3px;"></i></span>
              </el-tooltip>
          </template>
          <template slot-scope="scope">
                <el-input oninput="if(value.length==1){value=value.replace(/[^1-9]/g,'')}else{value=this.value.replace(/\D/g,'')}"     size="mini" min="1" v-model="scope.row.store_task_pasv.grab_number" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="row.store_task_pasv.task_duration" label="任务时效（分钟）" align="center">
          <template slot-scope="scope">
            <el-input size="mini" oninput="if(value.length==1){value=value.replace(/[^1-9]/g,'')}else{value=this.value.replace(/\D/g,'')}" v-model="scope.row.store_task_pasv.task_duration" placeholder="请输入"></el-input>
            <!-- <el-input-number v-model="scope.row.store_task_pasv.task_duration" controls-position="right"  :min="1" :max="255"></el-input-number> -->
          </template>
        </el-table-column>
        <el-table-column label="任务优先级" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.store_task_pasv.sort"
                       placeholder='请选择'>
              <el-option v-for="(item, index) in sortList" :key="index" :value="item.sort"
                         :label="item.name"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="任务触发" align="center" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.id==1||scope.row.id==2">立刻</div>
            <div v-else style="width: 100%;">
              <label class="select-label" v-if="scope.row.id==4">送餐后</label>
              <label class="select-label" v-else-if="scope.row.id==5">关房后</label>
              <label class="select-label" v-else>开房后</label>
              <el-input size="mini" style="width:60px;text-align:center;"
                        v-model="scope.row.store_task_pasv.trigger_duration"
                        placeholder="请输入"></el-input>
              <label class="select-label">分钟</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="接单兜底人员" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.store_task_pasv.last_user_id"
                       placeholder='请选择'>
              <el-option v-for="(item, index) in UserList" :key="index" :value="item.user_id"
                         :label="item.name"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick(scope.row)">保存</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>
<script>
import {
  // selectBrandList,
  // selectCompanyList,
  selectStationList,
  taskList,
  getUserList,
  saveTaskApi,
} from "../../../api/srv-store";

export default {
  name: 'brandManage',
  data() {
    return {
      checkBrand: '',
      checkCompany: '',
      brandList: [],
      companyList: [],
      stationList: [],//岗位列表
      allot_mode: [
        {name: '抢单', id: 1},
        {name: '先派单再抢单', id: 2}
      ],
      sortList: [{name: '一级', sort: 1}, {name: '二级', sort: 2}, {name: '三级', sort: 3}, {
        name: '四级',
        sort: 4
      }, {name: '五级', sort: 5}],
      tableData: [],//接口返回表格数据
      UserList: [],//门店员工列表
      selectedArray: [],
    }
  },
  created() {
    this.checkBrand = JSON.parse(localStorage.getItem('brandId'))
    this.checkCompany = JSON.parse(localStorage.getItem('storesId'))
    this.getTaskLit()
  },
  methods: {
    //获取任务列表
    getTaskLit() {
      let _this = this
      taskList({
        brand_id: _this.checkBrand,
        store_id: _this.checkCompany
      }).then(Er => {
        if (Er.return_code === 200) {
          _this.tableData = Er.data
          _this.getUserList()
          console.log(Er.data, "------------------")
          _this.tableData.map((item) => {
            if (item.store_task_pasv == null) {
              let obj = {
                "id": 2,
                "brand_id": 1,
                "store_id": 1,
                "task_pasv_id": 2,
                "name": "",
                "station_id": "",
                "allot_mode": '',
                "accept_duration": '',
                "task_duration": '',
                "trigger_duration": 0,
                "last_user_id": "",
                "sort": 1,
              }
              item.store_task_pasv = obj
            }
            if (item.store_task_pasv.station_id) {
              item.store_task_pasv.station_id = item.store_task_pasv.station_id.split(',')
            }
          })
        }
      })
    },
    //岗位全选事件
    selectAll(row) {
      this.selectedArray = row.store_task_pasv.station_id
      if (this.selectedArray.length < this.stationList.length) {
        this.selectedArray = []
        this.stationList.map((item) => {
          this.selectedArray.push(item.id)
        })
      } else {
        this.selectedArray = []
      }
      this.tableData.map(ite => {
        if (ite.id == row.id) {
          ite.store_task_pasv.station_id = this.selectedArray
        }
      })
    },
    //选择岗位事件
    changeSelect(row) {
      if (!row.store_task_pasv.station_id.includes('全选') && row.store_task_pasv.station_id.length === this.stationList.length) {
        console.log("0.0")
      } else if (row.store_task_pasv.station_id.includes('全选') && (row.store_task_pasv.station_id.length - 1) < this.stationList.length) {
        row.store_task_pasv.station_id = row.store_task_pasv.station_id.filter((item) => {
          return item != '全选'
        })
      }
    },
    //移除选中岗位事件
    removeTag(row) {
      console.log(row)
      if (row === '全选') {
        row = []
      }
    },
    //获取门店员工列表
    getUserList() {
      let _this = this
      getUserList({
        brand_id: _this.checkBrand,
        store_id: _this.checkCompany
      }).then(Er => {
        console.log(Er)
        _this.UserList = Er.data
        _this.getStationList()
      })
    },
    //获取岗位列表
    getStationList() {
      let _this = this
      selectStationList({
        brand_id: _this.checkBrand,
        store_id: _this.checkCompany
      }).then(Er => {
        if (Er.return_code === 200) {
          _this.stationList = Er.data
          _this.stationList.map(item => {
            item.id = item.id.toString()
          })
        }
      })
    },


    //点击保存
    handleClick(row) {
      console.log(row.id)
      let obj = {
        brand_id: this.checkBrand,
        store_id: this.checkCompany,
        sys_task_id: row.id,
        name: row.store_task_pasv.name,
        station_id: row.store_task_pasv.station_id.toString(),
        allot_mode: row.store_task_pasv.allot_mode,
        accept_duration: row.store_task_pasv.accept_duration,
        task_duration: row.store_task_pasv.task_duration,
        trigger_duration: row.store_task_pasv.trigger_duration,
        user_id: row.store_task_pasv.last_user_id,
        sort: row.store_task_pasv.sort,
        grab_number:row.store_task_pasv.grab_number
      }
      saveTaskApi(obj).then(Er => {
        if (Er.return_code === 200) {
          this.$message({
            type: 'success',
            message: Er.return_msg
          });
          this.getTaskLit()
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.brandManage {
  min-height: 100%;
  background-color: #f7fafb;

  .title_page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .main {
    background-color: #ffffff;
    height: 570px;
    padding: 40px;

    /deep/ .el-input__inner {
      padding: 0 5px;
      height: 30px;
      text-align: center;
      

      &::placeholder {
        color: rgba(62, 86, 110, 0.2);
        font-size: 14px;
      }
    }

    // /deep/ .el-form-item {
    //   margin-bottom: 0;
    //   padding: 25px 0;
    // }
    /deep/.el-input-number__decrease{
      background: none;
      border-right:none
    }

    /deep/  .el-input-number__increase {
        border-left: none;
        background: none
    }
    // /deep/.el-input-number.is-controls-right .el-input-number__increase{
    //   background: none;
    //   border-right:none
    // }
    // /deep/.el-input-number{
    //   width: 100%;
    // }
  }
}
</style>